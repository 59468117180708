<script setup>
  import { ref, onMounted, inject } from 'vue'

  import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue'
  import { appsOutline, personCircle, downloadOutline, pushOutline, logoOctocat, peopleCircleOutline } from 'ionicons/icons'

  const isAdmin = ref(false)
  const store = inject('store')
  const api = store.state.api_url
  const adminSts = localStorage.getItem(store.state.str_prefix + 'admin')
  const str_prefix = store.state.str_prefix

  const setAdmin = async () => {
    let q = await fetch(api + 'login?setAdmin', { method: 'post', body: JSON.stringify({ adminSts: adminSts }) })
    let r = await q.json()

    if (r[0] === 'success') {
      localStorage.setItem(str_prefix + 'session', r[1])
      localStorage.setItem(str_prefix + 'username', r[2])
      window.open('/admin/member')
      window.close()
    } else {
      localStorage.removeItem(str_prefix + 'session')
      localStorage.removeItem(str_prefix + 'loggedIn')
    }
  }

  onMounted(() => {
    isAdmin.value = localStorage.getItem(str_prefix + 'admin') == 1 ? true : false
  })
</script>

<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="dashboard" href="/member/dashboard">
          <ion-icon :icon="appsOutline" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="gethelp" href="/member/get-help">
          <ion-icon :icon="downloadOutline" />
          <ion-label>Get</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="providehelp" href="/member/provide-help">
          <ion-icon :icon="pushOutline" />
          <ion-label>Provide</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="network" href="/member/network">
          <ion-icon :icon="peopleCircleOutline" />
          <ion-label>Network</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="account" href="/member/profile">
          <ion-icon :icon="personCircle" />
          <ion-label>Account</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab5" @click="setAdmin()" v-if="isAdmin">
          <ion-icon :icon="logoOctocat" />
          <ion-label>Admin</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<style scoped>
  ion-tab-button {
    --color: rgb(34, 34, 34);
  }
  ion-tab-button ion-icon,
  ion-tab-button ion-label {
    font-weight: 600;
  }
  ion-tab-button ion-badge {
    font-size: 0.6rem;
    font-weight: 500;
  }
</style>
