<script setup>
  import { inject } from 'vue'
  import { IonLoading } from '@ionic/vue'

  const store = inject('store')
</script>

<template>
  <ion-loading :is-open="store.state.loading" message="Please wait..." spinner="dots" />
</template>
