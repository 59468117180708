<script setup>
  import { IonApp, IonRouterOutlet } from '@ionic/vue'
  import { provide, onMounted, ref } from 'vue'
  import store from '@/store'

  const w550 = ref(true)
  provide('store', store)

  onMounted(() => {
    let token = store.state.key
    if (token !== null && parseInt(token.slice(-2)) >= 15) {
      w550.value = false
    }
  })
</script>

<template>
  <ion-app :class="w550 ? 'w550' : ''">
    <ion-router-outlet />
    <loading-box />
  </ion-app>
</template>

<style scoped>
  .w550 {
    max-width: 550px;
    margin: 0 auto;
    /*  border: 1px solid #fff; */
  }
</style>
