import { createRouter, createWebHistory } from '@ionic/vue-router'
import TabsPage from '../views/TabsPage.vue'

const cekLogin = async () => {
  let loggedIn = localStorage.getItem('pgh_loggedIn')
  if (loggedIn === '0' || loggedIn === null) {
    return { path: '/login' }
  }
}

const cekUrl = async () => {
  let lastUrl = window.location.pathname.split('/')
  let ref = lastUrl.slice(-1).toString()
  let api_url = window.location.protocol === 'https:' ? './api/' : 'http://' + window.location.hostname + '/pgh30.com/api/'

  let q = await fetch(api_url + 'login?cekId=' + ref)
  let r = await q.json()

  if (r[1] == 'success') {
    localStorage.setItem('pgh_sponsor', r[0])
  }
  return { path: '/member/dashboard' }
}
const routes = [
  {
    path: '',
    redirect: '/login',
  },
  {
    path: '/:id',
    beforeEnter: cekUrl(),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/login',
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
  },
  {
    path: '/registration',
    component: () => import('@/views/RegistrasiPage.vue'),
  },
  {
    path: '/member/',
    component: TabsPage,
    beforeEnter: cekLogin,
    children: [
      {
        path: '',
        redirect: '/member/dashboard',
      },
      {
        path: 'dashboard',
        component: () => import('@/views/DashboardPage.vue'),
      },
      {
        path: 'get-help',
        component: () => import('@/views/GetHelp.vue'),
      },
      {
        path: 'provide-help',
        component: () => import('@/views/ProvideHelp.vue'),
      },
      {
        path: 'network',
        component: () => import('@/views/NetworkPage.vue'),
      },
      {
        path: 'profile',
        component: () => import('@/views/ProfilePage.vue'),
      },
    ],
  },
  {
    path: '/update-profil',
    component: () => import('@/views/UpdateProfil.vue'),
  },
  {
    path: '/update-password',
    component: () => import('@/views/UpdatePassword.vue'),
  },
  {
    path: '/system-plan',
    component: () => import('@/views/SystemPlan.vue'),
  },
  {
    path: '/member-support',
    component: () => import('@/views/MemberSupport.vue'),
  },
  {
    path: '/about-us',
    component: () => import('@/views/AboutUs.vue'),
  },
  {
    path: '/admin/',
    component: () => import('@/views/AdminPage.vue'),
    beforeEnter: cekLogin,

    children: [
      {
        path: '',
        redirect: '/admin/calon',
      },
      {
        path: 'calon',
        component: () => import('@/views/AdmCalon.vue'),
      },
      {
        path: 'member',
        component: () => import('@/views/AdmMember.vue'),
      },

      {
        path: 'support',
        component: () => import('@/views/AdmLayanan.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
