import { reactive } from 'vue'
import { toastController, alertController } from '@ionic/vue'
import { informationCircleOutline, closeCircleOutline } from 'ionicons/icons'

const str_prefix = 'pgh_'
const base_url = window.location.protocol === 'https:' ? './' : 'http://' + window.location.hostname + '/pgh30.com/'

const state = reactive({
  notifCounter: 0,
  notifAdmin: {
    calon: 0,
    member: 0,
    support: 0,
  },
  str_prefix: str_prefix,
  base_url: base_url,
  api_url: base_url + 'api/',
  key: localStorage.getItem(str_prefix + 'session') || '',
  lvl: localStorage.getItem(str_prefix + 'session') ? parseInt(localStorage.getItem(str_prefix + 'session').slice(-2)) : 0,
  method: { method: 'post', body: JSON.stringify({ key: localStorage.getItem(str_prefix + 'session') }) },
  loading: false,
  showProgress: false,
})

const methods = {
  async alertShow(message, tipe) {
    const toast = await toastController.create({
      message: message,
      duration: 2500,
      icon: tipe === 'success' ? informationCircleOutline : closeCircleOutline,
      color: tipe,
      position: 'top',
      cssClass: 'custom-toast',
    })
    return toast.present()
  },
  async modalBox(message, title) {
    const mBox = await alertController.create({
      header: title === 'success' ? 'Success' : title === 'danger' ? 'Failed' : title,
      message: message,
      buttons: ['OK'],
      cssClass: 'selectText',
    })
    return mBox.present()
  },
  rupiah: t => new Intl.NumberFormat('id-ID').format(t),
}

export default {
  state,
  methods,
}
